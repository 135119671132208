var term_data_todo_zh = `
服务条款的确认和接纳
万能清单的所有权和运作权归万能清单公司所有。在本服务条款中简称万能清单公司有时称为“我们”。使用万能清单产品及服务将视为您同意以下使用条款。

<strong>1、仅供个人使用</strong>

万能清单仅供用户个人用于非商业用途。如果要销售万能清单或者与万能清单有关或派生的任何资料、服务或软件，则必须得到万能清单公司的允许。如果用户对万能清单有任何意见，或有如何改进的建议，可向我们提出。请注意，如果这样做，还会授予万能清单和第三方在万能清单（或第三方软件）中无偿使用和加入用户的建议或意见的权利。

<strong>2、知识产权</strong>

用户承认万能清单公司拥有对万能清单的所有权利，包括但不限于所有知识产权。“知识产权”指在专利法、版权法、商业机密法、商标法、反不正当竞争法等法中规定的所有权利以及其中的所有应用、更新、扩展和恢复，无论在现在还是以后在全球范围内实施和生效。用户同意不会修改、改编、翻译万能清单、创作万能清单的派生作品、通过反编译、反向工程、反汇编或其它方式从万能清单得到源代码。用户同意不会删除、掩盖或更改万能清单公司或任何第三方的版权声明、商标或其它所有权声明。

<strong>3、用户隐私制度</strong>

尊重用户个人隐私是万能清单公司的一项基本政策。所以，万能清单公司一般不会公开、编辑或透露用户的注册资料或保存在万能清单公司服务中的非公开内容，除非万能清单公司在诚信的基础上认为透露这些信息在以下几种情况是必要的：

 · 遵守有关法律规定，包括在国家有关机关查询时，提供用户通过万能清单发布的信息内容及其发布时间、互联网地址或者域名。
 · 遵从万能清单的服务程序。
 · 保持维护万能清单公司的商标等知识产权。
 · 在紧急情况下竭力维护用户个人和社会大众的隐私安全。
 · 万能清单公司认为必要的其他情况下。

要了解万能清单数据保护措施的具体信息，请阅读万能清单的<a href="https://www.cigamtech.com/Privacy/zh/privacy18762/" target="_blank">隐私权政策</a>，该政策解释了当您使用万能清单的相关的服务时，万能清单会如何处理您的个人信息，以及如何保护您的隐私权。您同意会在遵守万能清单隐私权政策的前提下应用自己的数据。

<strong>4、服务变更</strong>

万能清单公司保留随时变更、中断或终止服务而不需通知用户的权利。用户接受万能清单公司行使变更、中断或终止服务的权利，万能清单公司不需对用户或第三方负责。

<strong>5、责任限制</strong>

用户理解并同意自主选择下载和使用万能清单和所有与万能清单一同提供或通过万能清单提供的第三方软件，风险自负，并且对于因下载或使用万能清单和此类第三方软件而对计算机系统造成的损坏或数据的丢失，用户应承担全部责任。在适用法律允许的最大范围内，万能清单公司明确表示不提供任何其他类型的保证，不论使明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。

在适用法律允许的最大范围内，万能清单公司及其许可人不会向您做出以下声明或担保：

 · 您对服务的使用会满足您的需求；
 · 您对服务的使用会连续无中断、及时、安全或没有错误；
 · 您使用相关服务而获得的任何信息一律准确可靠；
 · 作为相关服务的一部分向您提供的任何软件所发生的操作或功能瑕疵将获得修正。

在适用法律允许的最大范围内，万能清单公司不就因用户使用万能清单引起的，或在任何方面与万能清单公司的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。用户上载的行为，即意味着用户或用户代理的著作权人授权万能清单公司及其关联公司在万能清单的服务器上保存内容，但用户或原著作权人仍保有上载作品的著作权，且万能清单公司未经用户或原著作权人许可不得将上载作品在其他公开渠道发布。如用户在使用万能清单时涉及到其他万能清单公司或其关联公司提供的服务，还应遵守相应的服务条款。

<strong>6、保障</strong>

用户同意保障和维护万能清单公司的利益，负责支付由用户违反本服务条款或万能清单公司其他服务条款而引起的律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿费用等。

<strong>7、法律</strong>

上述条款将受中华人民共和国法律的约束并依据其解释。如出现纠纷，用户和万能清单公司一致同意将纠纷交由当地人民法院管辖。如用户对本服务条款内容有任何疑问，可发邮件至<a href="mailto:cigamtech@outlook.com">cigamtech@outlook.com</a>或访问<a href="https://www.cigamtech.com" target="_blank">www.cigamtech.com</a>获取相关信息。
`
var term_data_todo_zh = term_data_todo_zh.replace(/\r\n/g,"<br>")
var term_data_todo_zh = term_data_todo_zh.replace(/\n/g,"<br>");

export default term_data_todo_zh