import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Navigation from "sections/Navigation"
import Footer from "sections/Footer-classic"

import Privacy from '../../../mySections/BlogContent/index'
import PrivacyContent from '../../../mySections/TermOfUseData/zh/termZ_data_todo'

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

const appName_zh = "万能清单"

const Terms_Todo_Zh = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Next To Do Terms" />
      <Navigation pageName="404" />
      <Privacy
        title={appName_zh + "使用条款"}
        content={PrivacyContent}
      />
      <Footer />
    </Layout>
  </ThemeProvider>
)
export default Terms_Todo_Zh